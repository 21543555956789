<template>
  <v-container fluid>
    <v-row class="backgroundTopo">
      <v-col cols="12" md="8" class=" justify-center d-flex flex-column">
        <h1 class="textoTopo" style="font-weight:400;
         font-size:48px;
         color: #fff;
         line-height: 1;
         font-family: Bree Serif, Poppins;
         padding-left:40px">Espaço de trabalho ambientado e preparado para você!</h1>
      </v-col>
    </v-row>

    <v-row class="align-center justify-center d-flex flex-column" style="padding-bottom:10px"  data-aos="fade-up" data-aos-duration="1500">
      <v-col cols="12" md="2"></v-col>
      <v-col cols="12" md="8" class="align-center justify-center d-flex flex-column ">
        <h1 class="padTexto" style="font-weight:400;
         font-size:48px;
         color: #5398a9;
         line-height: 1;
         font-family: Bree Serif, Poppins;
         padding-left:40px">Sobre a Solanute</h1>
        <p class="padTexto" style="padding-left:20px; font-size: 22px;">Uma nova forma de enxergar um
        consultório médico. Conheça a nossa clínica e apresentaremos para você nossas
          dependências e como nossa estrutura poderá auxiliar você!</p>
      </v-col>
    </v-row>

    <v-row style="padding:0px 100px" class="d-flex justify-center align-center padRow" data-aos="fade-up" data-aos-duration="1500">
      <v-col v-for="(item, i) in items" :key="item.image" cols="12" :md="i in [1, 1] ? '3' : '6'">
        <v-card class="mx-auto b-radius bgcCards align-end">
          <v-img height="200px" min-height="200px" :src="require(`../assets/${item.image}`)"></v-img>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="backgroundMeio" style="margin-bottom: 80px !important">
      <v-col cols="12" md="12" class="align-center justify-center d-flex flex-column" data-aos="fade-right" data-aos-duration="1500">
        <h1 class="fontMobile" style="color:#fff;
      font-size:40px;
      font-family: Bree Serif, Poppins;
      opacity:90%;
      text-align: center;
      padding-bottom: 10px">"Localizado na região de maior potencial de Sorocaba"</h1>
        <!-- <p data-aos="fade-left" data-aos-duration="1500" class="dostoiMb" style="color:#fff;
      font-size:48px;
      letter-spacing: 5px;
      font-family: Bree Serif, Poppins;">(Sorocaba)</p> -->
      </v-col>
    </v-row>

    <v-row style="padding-bottom: 50px"  data-aos="fade-right" data-aos-duration="1500">
      <v-col cols="12" md="6" class="justify-center d-flex flex-column ">
        <h1 class="padTexto" style="font-weight:400;
         font-size:50px;
         color: #5398a9;
         line-height: 1;
         font-family: Bree Serif, Poppins;
         padding-left:40px;
         padding-bottom: 20px">Saiba mais e seja <br> um de nós</h1>
        <p class="padTexto" style="padding-left:40px; font-size:22px">Preencha o formulário com os seus dados e retornaremos o seu contato à seguir:.</p>
      </v-col>
      <v-col cols="12" md="6" class="justify-center d-flex flex-column align-center">
        <div class="d-flex justify-center flex-column positionMobile align-center" style="background-color: #f1f1f1;
                 border-radius: 30px;
                 padding: 80px 50px;
                 opacity:;
                 box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                 width: 500px;
                 height: 485px;">

          <v-text-field background-color="#fff" style="border-radius:20px; width:500px" outlined v-model="contato.nome"
            label="Nome" required>
          </v-text-field>
          <v-text-field background-color="#fff" style="border-radius:20px; width:500px" outlined v-model="contato.email"
            label="E-mail" required>
          </v-text-field>
          <v-text-field background-color="#fff" style="border-radius:20px; width:500px" v-mask="'(##) #####-####'"
            outlined v-model="contato.telefone" label="Whatsapp" required>
          </v-text-field>
          <v-btn :disabled="loading" @click="enviarFormulario" class="mr-4" type="button" height="50px" style="background-color: #4490a3; color: #fff; 
            font-family: Bree Serif, Poppins; font-weight: 600; font-size:15px; border-radius: 30px;
            text-transform: inherit; margin-top: 25px; margin-right: 0px !important;
            width:200px;"> Enviar
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "sobre",
  data: () => ({
    items: [
      { image: "clinica.webp", },
      { image: "doutor.webp", },
      { image: "crianca.webp", },
      { image: "cabeca.webp", },
      { image: "medico.webp", },
    ],
    loading: false,
    snackbar: false,
    text: "Seu contato foi enviado! Obrigado por sua mensagem, responderemos em breve.",
    contato: { nome: "", email: "", telefone: "", },
    timeout: 2000,
  }),

  mounted() {
    // window.scrollTo(0, 0);
    window.scrollTo(0, 0);
  },

  methods: {
    enviarFormulario() {
      if ((this.contato.nome === "")) { 
                    alert("Por favor preencha todos os campos")
                    return;
                }
                else if ((this.contato.email === "")){
                    alert("Por favor preencha todos os campos")
                    return;
                }
                else if ((this.contato.telefone === "")){
                    alert("Por favor preencha todos os campos")
                    return;
                }
            else{
            this.loading = true;
      fetch("https://gestao.abare.cloud/items/leads", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ id_formulario: 75, lead: this.contato }),
      })
        .then((response) => response.text())
        .then((data) => {
          window.console.log(data);
          // alert("contato enviado com sucesso");
          this.snackbar = true;
          this.enviarEmail();
        });
      }
    },

    enviarEmail() {
            fetch("https://gestao.abare.cloud/email/gen-send", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify({
                    novo: true,
                    template: "d-6f0c80f846af4963a599785c85ccef8e",
                    email: 'recepcao@solanute.com.br',
                    dynamic: {
                        nome: this.contato.nome,
                        email: this.contato.email,
                        telefone: this.contato.telefone,
                    }
                }),
            })
                .then((response) => response.text())
                .then(() => {
                    //this.$router.push("obrigado");
                    this.r = {};
                });
        },
  },
}
</script>

<style scoped>
@media (min-device-width: 340px) and (max-device-width: 731px) {
  .positionMobile {
    position: relative !important;
    right: 0 !important;
    top: initial !important;
    padding: 50px 30px !important;
    width: 360px !important;
  }

  .padRow{
    padding: 0px 0px !important;
  }
  .textoTopo {
    line-height: 1.1;
    font-size: 20px;
    padding-left: 10px !important;
  }

  .fontMobile {
    font-size: 40px !important;
    line-height: 1 !important;
  }

  .dostoiMb {
    line-height: 1 !important;
  }

  .padTexto {
    padding-left: 10px !important;
  }
}

.backgroundTopo {
  background-image: url("../assets/clinica.webp");
  background-repeat: no-repeat !important;
  background-size: cover;
  height: 450px;
  background-attachment: fixed;
  background-color: rgba(255, 255, 255, 0.596);
  background-position: center-top;
}



.backgroundMeio {
  background-image: url("../assets/doutor.webp");
  background-repeat: no-repeat !important;
  background-size: cover;
  height: 250px;
  background-attachment: fixed;
  background-color: rgba(255, 255, 255, 0.596);
  background-position: center;
}
</style>
