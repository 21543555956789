<template>
<v-container fluid>
    <v-row class="backgroundFundo">
        <v-col class="align-center justify-center d-flex" cols="12" md="6">
            <h1 data-aos="fade-right" data-aos-duration="1500" style="
            color: #fff;
            font-size: 50px;
            line-height: 1;
            opacity: 0.8;
            padding-top: 320px;
            font-weight: 800;
            font-family: Bree Serif;
            ">Fique ligado!</h1>
        </v-col>
    </v-row>

    <v-row data-aos="fade-up" data-aos-duration="1500" style="margin-bottom: 100px;" class="d-flex padFooter justify-center align-center">
<transition
          name="cards"
          appear
         v-for="(blog, i) in blogs" :key="blog.titulo">
          <v-col   cols="12" :md="i in [1, 1] ? '3' : '6'" class="padding2px" >
            <v-hover>
              <template v-slot:default="{ hover }">
              
              <v-card class="mx-auto b-radius  align-end">
                 <v-img
                    :src="`https://blogs.abare.cloud/assets/${blog.imagem}`"
                    height="300px"
                    width="100%"
                    min-width="100%"
                    min-height="300px"
                    class=""
                  >
                    <v-card-title class="fontPoppins colorText">
                      {{ blog.titulo.substring(0, 20)
                      }}{{ blog.titulo.length > 19 ? "..." : "" }}
                    </v-card-title>
                     <v-card-subtitle
                      class="fontPoppins colorText"
                      style="align; word-break: normal;"
                    v-html="blog.descricao.substring(0, 250) + '...'"
                    >
                    </v-card-subtitle>     
                  </v-img>
                 
                 <v-fade-transition >
                   <v-overlay style="width: 100% !important" v-if="hover" absolute color="#034063">
                      <router-link :to="'/read/' + blog.id" style="text-decoration: none !important">
                        <v-btn
                          color="transparent"
                          style="opacity: 0.9;
                          color: #fff !important;
                          border: 2px solid #fff !important;
                          "
                          dark
                          >Ver mais</v-btn>
                      </router-link>
                    </v-overlay>
                  </v-fade-transition>
              </v-card>
              </template>
            </v-hover>
          </v-col>
          </transition>
            
      </v-row>
  </v-container>
</template>

<script>
export default {
  overlay: "false",
  name: "blog",
  data: () => ({
     blogs: [],
  }),

  mounted() {
    // window.scrollTo(0, 0);
    window.scrollTo(0, 0);
  },

  created() {
    fetch("https://blogs.abare.cloud/items/solanute?filter[status][_eq]=published&sort=-date_created")
      .then((response) => response.json())
      .then((data) => {
        this.blogs = data.data;
      });
  },

    metaInfo() {
    return {
      title: "Blog - Abaré Marketing",
      meta: [
        {
          name: "descricao",
          content:
            "Fique atualizado, quer saber mais sobre nossas tecnologias, cultura ou acessar materiais que podem ajudar a alavancar a sua carreira?.",
        },
        { property: "og:title", content: "Blog - Abaré Marketing" },
        { property: "og:site_name", content: "Abaré Marketing" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },

};
</script>

<style scoped>
@media (min-device-width: 360px) and (max-device-width: 640px) {
  .container {
    width: 100%;
    padding: 0px !important;
    margin-right: auto;
    margin-left: auto;
  }


  .padFooter {
    padding: 10px 8px 10px 8px !important;
  }

  .row {
    margin: 0px 0px !important;
  }

}

.align-end {
  align-items: end !important;
}

.padding2px {
  padding: 2px !important;
}

.colorText {
  color: #ffffff;
}

.container {
  padding: 0 !important;
}


.padFooter {
  padding: 20px 110px 10px 110px;
}

.backgroundFundo {
  background-image: url("../assets/doutor.webp");
  background-repeat: no-repeat !important;
  background-size: cover;
  height: 500px;
  background-attachment: absolute;
  background-color: rgba(255, 255, 255, 0.596);
  background-position: center-top;
}

</style>