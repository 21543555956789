<template>
    <v-container fluid>
     <v-row justify="center" align=center >
        <v-col cols="12" md="8" >
          <div class="justify-center align-center d-flex flex-column marginMedia" >
             <v-img 
            :src="`https://blogs.abare.cloud/assets/${blog.imagem}`" v-if="blog.id"
            width="70%"
          > </v-img>
          </div>
          <h2 class="titleBlog text-center">{{ blog.titulo }}</h2>
          <v-divider></v-divider>
          <div class="texts align-center justify-center" style="font-family: poppins"  v-html="blog.texto"></div>
          <router-link to="/fiquePorDentro">
          <v-btn style="margin-top:30px; background-color:#1B3765; color:#fff">Voltar</v-btn>
          </router-link>
        </v-col>
     </v-row>

      <transition name="social" appear>
        <v-row style="background-color: #F0F0F0; height: 30vh">
        <v-col cols="12" md="3"></v-col>
          <v-col cols="12" md="6" class="text-center d-flex justify-center align-center">
            <twitter-button
              shareUrl="https://github.com/"
              shareDescription="GitHub is where people build software."
            />
            <facebook-button
              shareUrl="https://github.com/"
              shareDescription="GitHub is where people build software."
            />
          </v-col>
          <v-col cols="12" md="3"></v-col>
    </v-row>
    </transition>
    </v-container>
 
    </template>


<script>
import TwitterButton from "vue-share-buttons/src/components/TwitterButton";
import FacebookButton from "vue-share-buttons/src/components/FacebookButton";
export default{
    components: { TwitterButton, FacebookButton,},
data: () => ({
    blog:{},
    nameRules: [
      (v) => (v && v.length <= 100) || "Name must be less than 10 characters",
    ],

    emailRules: [(v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
}),

mounted() {
    window.scrollTo(0, 0);
  },

  created() {
    let idblog = this.$route.params.idblog;
    fetch("https://blogs.abare.cloud/items/solanute/" + idblog)
      .then((response) => response.json())
      .then((data) => {
        this.blog = data.data;
      });
  },

   metaInfo() {
    return {
      title: this.blog.titulo,
      meta: [
        {
          name: "descricao",
          content: this.blog.descricao,
        },
        { property: "og:title", content: this.blog.titulo },
        { property: "og:site_name", content: "Abaré Marketing" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },


}
</script>


<style scoped>
@media (min-device-width: 360px) and (max-device-width: 731px) {
    .texts {
  font-family: roboto;
  font-weight: 300;
  color: #000007;
  word-break: none;
}
.marginMedia{
  margin-top:30px !important;
}
}
.titleBlog {
  font-weight: 900;
  color: #0a0024;
  font-family: Poppins;
  line-height: 1;
  letter-spacing: -1px;
  padding: 30px 0px;
}

.v-image__image--cover{
  background-size: contain !important;
}

.texts {
  font-family: roboto;
  font-weight: 300;
  color: #000007;
}
</style>
