<template>
  <v-container fluid>
    <v-row class="backgroundTopo">
      <v-col cols="12" md="8" class=" justify-center d-flex flex-column paddingCol" style="padding-left:60px">
        <h1 class="padTexto textoTitulo" data-aos="fade-right" data-aos-duration="1500" style="font-weight:400;
         font-size:50px;
         line-height: 1;
         color: #fff;
         padding-bottom:20px;
         font-family: Bree Serif, Poppins;">A melhor escolha para seu consultório médico.</h1>
        
        <v-btn class="textoBotao" data-aos="fade-right" data-aos-duration="1500" @click="dialog = true" style="color:#157d7d; 
        background-color: #ffffffd4;
        font-family:Bree Serif;
        font-size: 30px;
        padding-top:20px;
        border-radius:40px;
        text-transform:unset;
        padding: 30px 5px !important;
        font-weight:800;
        border: solid 1px #5398a9;
        width:400px">
        Agende uma visita</v-btn>
      </v-col>
    </v-row>

    <v-row class="align-center  justify-center d-flex flex-column" data-aos="fade-up" data-aos-duration="1500">
      <v-col cols="12" md="2"></v-col>
      <v-col cols="12" md="8" class="align-center justify-center d-flex flex-column">
        <h1 class="padTexto padMobile" style="font-weight:400;
         font-size:40px;
         color: #5398a9;
         font-family: Bree Serif, Poppins;
         line-height: 1;
         padding-left:40px;
         padding-bottom:20px">Seja um médico parceiro;
        </h1>
        <p class="padTexto textoCentral padMobile" style="padding-left:20px; text-align: center;">A estrutura Solanute
          possibilita, ao profissional da saúde, a assistência e recursos que permitem a otimização de sua rotina
        </p>
      </v-col>
    </v-row>


    <v-row class="align-center justify-center d-flex" style="padding-bottom:50px" data-aos="fade-up"
      data-aos-duration="1500">
      <v-col cols="12" md="4" style="min-height:450px; height: 450px; justify-content: flex-start !important;"
        class="align-center d-flex flex-column padCol">
        <div style="min-height:150px">
          <v-img class="wdImage" width="120" contain src="../assets/sad.webp"></v-img>
        </div>
        <h1
          style="font-size:25px; text-align:center; font-weight:800; color:#004343; padding-bottom: 15px; line-height: 1;">
          Alternativa
          1:<br>Abrir um
          consultório próprio</h1>
        <p style="font-size: 16px; font-weight:500;  padding-left:40px">- Investimento inicial alto e despesa média de
          R$10mil por mês
          <br> - 65% de ociosidade média do espaço na semana <br>
          - Problemas para contratar pessoas e administrar o dia a dia
        </p>
      </v-col>
      <v-col cols="12" md="4" style="min-height:450px; height: 450px; justify-content: flex-start !important;"
        class="align-center d-flex flex-column">
        <div style="min-height:150px">
          <v-img class="wdImage" width="120" contain src="../assets/sad.webp"></v-img>
        </div>
        <h1
          style="font-size:25px; text-align:center; font-weight:800; color:#004343; padding-bottom: 15px; line-height: 1;">
          Alternativa
          2:<br>Atender em
          clínicas e hospitais</h1>
        <p style="font-size: 16px; font-weight:500;  padding-left:20px">- Repasse da maior parte do atendimento para
          a clínica ou convênio <br>
          - Recebimento em 60-90 dias após o atendimento
          realizado <br>
          - Necessidade de atender em vários locais e o
          paciente não é seu</p>
      </v-col>
      <v-col cols="12" md="4" style="min-height:450px; height: 450px; justify-content: flex-start !important;"
        class="align-center d-flex flex-column padCol">
        <div style="min-height:150px">
          <v-img class="wdImage" width="120" contain src="../assets/feliz.webp"></v-img>
        </div>
        <h1
          style="font-size:25px; text-align:center; font-weight:800; color:#004343; padding-bottom: 15px;  line-height: 1;">
          Alternativa
          3:<br>Atender na
          Solanute</h1>
        <p style="font-size: 16px; font-weight:500;  padding-left:20px; padding-right:20px;">- Usa somente quando
          precisa, com um valor justo
          e
          tudo incluso, até prontuário eletrônico e secretária <br>
          - Consultório sofisticado para chamar de seu
          com decoração personalizada <br>
          - Localizado na região de maior potencial de Sorocaba</p>
      </v-col>
      <v-col cols="12" md="6" class="align-center justify-center d-flex flex-column" style="padding-top:40px;">
        <v-btn @click="dialog = true"  style="background-color:#004343;
      color:#fff;
      border-radius:20px; font-size: 25px; text-transform: unset; font-family: Bree Serif;">Quero fazer parte</v-btn>
      </v-col>
    </v-row>


    <!-- <v-row class="bgRetangulo align-center justify-center" data-aos="fade-up" data-aos-duration="1500">
      <v-col cols="12" md="12">
        <div class=" columMB" style="display:flex">
          <v-col cols="12" v-for="blog in blogHome" :key="blog.titulo" md="4" class="padding0Mob"
            style="padding: 5px 8px">
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card class="mx-auto bgcCards justify-center"
                  style="min-height: 480px; max-height: 480px; border-radius:10px">
                  <v-img :src="`https://blogs.abare.cloud/assets/${blog.imagem}`" min-height="295px" max-height="295px"
                    style="align-items: end !important;" class="justify-center align-center">
                    <v-card-title class="fontRoboto colorText WhidtMobile" style="
                    font-family: poppins !important;
                    letter-spacing: -1px;
                    word-break: break-word;
                    color: #fff;
                    background-color:#0b3374cf !important;
                    font-size: 1.5rem !important;
                  ">
                      {{ blog.titulo }}
                    </v-card-title>

                  </v-img>
                  <div>
                    <v-card-text style="line-height: 1.3 !important" v-html="blog.texto.substring(0, 300) + '...'">
                    </v-card-text>
                  </div>
                  <v-fade-transition>
                    <v-overlay v-if="hover" absolute color="#034063">
                      <router-link :to="'/read/' + blog.id">
                        <v-btn color="transparent" style="opacity: 0.8; color: #fff !important; border: solid 3px #fff !important;
                        text-decoration: none !important">Ver mais</v-btn>
                      </router-link>
                    </v-overlay>
                  </v-fade-transition>
                </v-card>
              </template>
            </v-hover>
          </v-col>
        </div>
      </v-col>
      <v-row class="align-center justify-center d-flex rowBtn" style="padding-top: 20px; padding-bottom: 20px;">
        <v-col cols="12" md="12" class="align-center justify-center d-flex">
          <router-link to="fiquePorDentro" style="text-decoration: none">
            <v-btn style="background-color:#c3d1d1; border-radius:20px; color:#204545">Veja mais de nossos blogs!
            </v-btn>
          </router-link>
        </v-col>
      </v-row>
    </v-row> -->

    <!-- <v-row class="align-center d-flex bgRetangulo justify-center" style="padding: 35px 0px; " data-aos="fade-up"
      data-aos-duration="1500">
      <v-col cols="12" md="12" class="align-center justify-center d-flex">
        <h1 class="padTexto" style="font-weight:400;
        font-size:40px;
         line-height: 1;
         color: #fff;
         padding-bottom:20px;
         font-family: Bree Serif, Poppins;
         padding-left:40px">O que os médicos dizem?</h1>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="mx-auto wdCard" max-width="450" style="height: 366px; align-items: center;  display: flex;"
          outlined data-aos="fade-up" data-aos-duration="1500">
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">
                CRM: 000000
              </div>
              <v-list-item-title class="text-h5 mb-1" style="padding-bottom:20px">
                Dra. Maria Nogueira
              </v-list-item-title>
              <v-list-item-text>A Solanute é incrível pois me dá o conforto, comodidade e sofisticação
                necessária para receber meus pacientes sem precisar me preocupar com nada. Fiquei super impressionada
                com o nível do serviço prestado.</v-list-item-text>
            </v-list-item-content>
            <v-list-item-avatar tile size="80">
              <v-img style="border-radius:50px" src="../assets/mulher.webp"></v-img>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" class="align-center d-flex flex-column colMb" style="max-width: 40% ">
        <v-card class="mx-auto wdCard" max-width="500" width="500" outlined data-aos="fade-up" data-aos-duration="1800">
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">
                CRM: 000000
              </div>
              <v-list-item-title class="text-h5 mb-1" style="padding-bottom:20px">
                Dr. João Pereira
              </v-list-item-title>
              <v-list-item-text>Após pesquisar muito, a Solanute foi a melhor opção que encontrei para ter meu
                consultório próprio a um preço justo e sem precisar dividir meus ganhos com clínicas e convênios.
              </v-list-item-text>
            </v-list-item-content>
            <v-list-item-avatar tile size="80">
              <v-img style="border-radius:50px" src="../assets/velho.webp"></v-img>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
        <v-card class="mx-auto cardMb wdCard" max-width="500" width="500" outlined data-aos="fade-up"
          data-aos-duration="2000">
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">
                CRM: 000000
              </div>
              <v-list-item-title class="text-h5 mb-1" style="padding-bottom:20px;">
                Dra. Fernanda <br> Olivares
              </v-list-item-title>
              <v-list-item-text>A Solanute oferece uma estrutura completa e um ambiente de muito bom gosto,
                agregando qualidade para meu atendimento e evitando custos e tempo para manter e gerenciar um
                consultório prórpio.</v-list-item-text>
            </v-list-item-content>
            <v-list-item-avatar tile size="80">
              <v-img style="border-radius:50px" src="../assets/dotora.webp"></v-img>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row> -->

    <v-row class="align-center justify-center d-flex" data-aos="fade-up" data-aos-duration="1500"
      style="background-color:#004343; margin-bottom:40px !important;">
      <v-col cols="12" md="6" class="align-center d-flex justify-center">
        <h1 class="padTexto" style="font-weight:400;
        font-size:40px;
         line-height: 1;
         color: #fff;
         padding-bottom:20px;
         font-family: Bree Serif, Poppins;
         padding-left:60px"> Porque a Solanute como sua opção:</h1>
      </v-col>
      <v-col cols="6" md="2" class="align-center justify-center d-flex " style="padding-right:50px">
        <div class="d-flex align-center flex-column">
          <v-img class="  wdImage" max-width="60px" src="../assets/disponivel.webp"></v-img>
          <p class=" padMobile"
            style="font-size: 18px; font-weight:500; color:#fff; font-weight: 300 !important; text-align: center;">
            Seu consultório sempre
            disponível</p>
        </div>
      </v-col>

      <v-col cols="6" md="2" class="align-center justify-center d-flex"  style="padding-right:50px">
        <div class="d-flex align-center flex-column">
          <v-img class=" wdImage" max-width="60px" src="../assets/predio.webp"></v-img>
          <p class=" padMobile"
            style="font-size: 18px; font-weight:500; color:#fff; text-align: center; font-weight: 300 !important;">
            Local estruturado para iniciar o seu negócio</p>
        </div>
      </v-col>

        <v-col cols="6" md="2" class="align-center justify-center d-flex"  style="padding-right:50px">
        <div class="d-flex align-center flex-column">
          <v-img class=" wdImage" max-width="60px" src="../assets/gps.webp"></v-img>
          <p class=" padMobile"
            style="font-size: 18px; font-weight:500; color:#fff;  text-align: center; font-weight: 300 !important;">
            Ótima localização e condições para você</p>
        </div>
      </v-col>
    </v-row>


    <v-row class="align-center justify-center d-flex" data-aos="fade-right" data-aos-duration="1500"
      style="padding-top:50px; padding-bottom: 50px; margin-bottom:40px !important; margin:0px !important; padding:0px !important">
      <v-col cols="12" md="6" class="align-center d-flex justify-center flex-column"
        style="margin:0px !important; padding:0px !important">
        <h1 class="padTexto" style="font-weight:400;
        font-size:40px;
         line-height: 1;
         color: #5398a9;
         padding-bottom:20px;
         font-family: Bree Serif, Poppins;
         padding-left: 100px 
         ">Como posso ter o meu consultório Solanute?</h1>
        <p class="padTexto textoCentral padMobile">Conte com a melhor estrutura e recursos
          para otimizar sua rotina.
        </p>
      </v-col>
      <v-col class="colMb align-center justify-center " cols="12" md="5"
        style=" padding:0px !important; margin:0px !important; ">
        <v-img @click="dialog = true" class="wdMb" width="400" style="cursor:pointer" src="../assets/jornada1.webp">
        </v-img>
      </v-col>
    </v-row>

    <v-row class="especialidade padBorder" data-aos="fade-up" data-aos-duration="1500"
      style="background-color:#157d7d; margin: 0px !important; border-radius: 50px; margin-top: 35px;">

      <v-col cols="12" md="12" class="align-center d-flex justify-center">
        <h1 style="color:#fff; font-size:28px; font-family: bree serif, Poppins">Algumas especialidades que a Solanute oferece estrutura</h1>
      </v-col>
      <v-col cols="6" md="3" data-aos="fade-up" data-aos-duration="1800">
        <v-card class="d-flex align-center justify-center flex-column widthCard  mx-auto"
          style="border-radius:20px; background-color: transparent !important; box-shadow: none; max-width:344">
          <v-img class="wdEsp" style="border-radius:20px" src="../assets/pedia.webp" height="80px" contain></v-img>
          <v-card-title style="color: #fff; font-size: 18px; font-size: 18px">
            Pediatria
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="6" md="3" data-aos="fade-up" data-aos-duration="2000">
        <v-card class="d-flex align-center justify-center flex-column widthCard  mx-auto"
          style="border-radius:20px; background-color: transparent !important; box-shadow: none; max-width:344">
          <v-img class="wdEsp" style="border-radius:20px" src="../assets/cardio.webp" height="80px" contain></v-img>
          <v-card-title style="color: #fff; font-size: 18px">
            Cardiologia
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="6" md="3" class="d-flex" data-aos="fade-up" data-aos-duration="2200">
        <v-card class="d-flex align-center justify-center flex-column  widthCard mx-auto"
          style="border-radius:20px; background-color: transparent !important; box-shadow: none; max-width:344">
          <v-img class="wdEsp" style="border-radius:20px" src="../assets/fisio.webp" height="80px" contain></v-img>
          <v-card-title style="color: #fff; font-size: 18px">
            Fisioterapia
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="6" md="3" class="d-flex" data-aos="fade-up" data-aos-duration="2200">
        <v-card class="d-flex align-center justify-center flex-column widthCard  mx-auto"
          style="border-radius:20px; background-color: transparent !important; box-shadow: none; max-width:344">
          <v-img class="wdEsp" style="border-radius:20px" src="../assets/neuro.webp" height="80px" contain></v-img>
          <v-card-title style="color: #fff; font-size: 18px">
            Neurologia
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="6" md="3" data-aos="fade-up" data-aos-duration="1800">
        <v-card class="d-flex align-center justify-center flex-column widthCard  mx-auto"
          style="border-radius:20px; background-color: transparent !important; box-shadow: none; max-width:344">
          <v-img class="wdEsp" style="border-radius:20px" src="../assets/ortopedia.webp" height="80px" contain></v-img>
          <v-card-title style="color: #fff; font-size: 18px">
            Ortopedia
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="6" md="3" data-aos="fade-up" data-aos-duration="2000">
        <v-card class="d-flex align-center justify-center flex-column widthCard  mx-auto"
          style="border-radius:20px; background-color: transparent !important; box-shadow: none; max-width:344">
          <v-img class="wdEsp" style="border-radius:20px" src="../assets/pneumologia.webp" height="80px" contain>
          </v-img>
          <v-card-title style="color: #fff; font-size: 18px">
            Pneumologia
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="6" md="3" class="d-flex" data-aos="fade-up" data-aos-duration="2200" style="padding-bottom:40px">
        <v-card class="d-flex align-center justify-center flex-column widthCard  mx-auto"
          style="border-radius:20px; background-color: transparent !important; box-shadow: none; max-width:344">
          <v-img class="wdEsp" style="border-radius:20px" src="../assets/dermatology.webp" height="80px" width="90px"
            contain></v-img>
          <v-card-title style="color: #fff; font-size: 18px">
            Dermatologia
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="6" md="3" class="d-flex" data-aos="fade-up" data-aos-duration="2200">
        <v-card class="d-flex align-center justify-center flex-column widthCard  mx-auto"
          style="border-radius:20px; background-color: transparent !important; box-shadow: none; max-width:344;">
          <v-img class="wdEsp" style="border-radius:20px" src="../assets/plastica.webp" height="80px" contain></v-img>
          <v-card-title style="color: #fff; font-size: 18px">
            Cirurgia <br> Plástica
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6" class=" d-flex flex-column ultimaCol" style="padding-top:50px; padding-bottom:50px">
        <h1 class="padTexto" style="font-weight:400;
        font-size:40px;
         line-height: 1;
         color: #5398a9;
         padding-bottom:20px;
         font-family: Bree Serif, Poppins;
         padding-left: 60px 
         ">Vantagens de ser parceiro Solanute</h1>

         <p class="padTexto" style="font-size:20px; padding:40px 60px; font-family:Poppins">
         -Secretária incluso.<br><br>
         -Disponibilidade de consultório sempre que precisar.<br><br>
         -Estrutura feita pensada para atender suas necessidades profissionais.</p>
      </v-col>
      <v-col cols="12" md="6" class="align-center justify-center d-flex ultimaCol" style="padding-top:50px; padding-bottom:50px">
        <div class="d-flex justify-center flex-column positionMobile align-center" style="background-color: #f1f1f1;
                 border-radius: 30px;
                 padding: 80px 50px;
                 opacity:;
                 box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                 width: 500px;
                 height: 485px;">

                 <h1 class="padTexto"
                    style="color:#157d7d; font-size:35px; font-weight:800; font-family: Bree serif; padding-left:0px; padding-bottom:20px; line-height:1">
                    Entre em contato agora:</h1>

          <v-text-field background-color="#fff" style="border-radius:20px; width:500px" outlined v-model="contato.nome"
            label="Nome" required>
          </v-text-field>
          <v-text-field background-color="#fff" style="border-radius:20px; width:500px" outlined v-model="contato.email"
            label="E-mail" required>
          </v-text-field>
          <v-text-field background-color="#fff" style="border-radius:20px; width:500px" v-mask="'(##) #####-####'"
            outlined v-model="contato.telefone" label="Whatsapp" required>
          </v-text-field>
          <v-btn :disabled="loading" @click="enviarFormulario" class="mr-4" type="button" height="50px" style="background-color: #4490a3; color: #fff; 
            font-family: Bree Serif, Poppins; font-weight: 600; font-size:15px; border-radius: 30px;
            text-transform: inherit; margin-top: 25px; margin-right: 0px !important;
            width:200px;"> Enviar
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row></v-row>

    <!-- <v-row>
      <v-col cols="12" md="12" class="align-center justify-center" style="padding-top:60px">
        <h1 style="font-weight:400;
         font-size:30px;
         padding-left:20px">Conheça a Solanute</h1>
        <p style="color:#000; font-size:15px;  padding-left:20px">
          Lorem ipsum habitasse placerat suspendisse iaculis nec ullamcorper aliquam, quisque adipiscing malesuada elit
          nulla orci platea tortor, pharetra aliquam convallis quam euismod morbi viverra non, nam fringilla aptent a id
          congue dolor.</p>
      </v-col>
    </v-row> -->
    <v-dialog v-model="dialog" max-width="490" fullscreen>
      <v-card class="backgroundDialog">

        <v-btn class="" icon dark @click="dialog = false">
          <v-icon style="color:#fff">mdi-close</v-icon>
        </v-btn>

        <v-row style="height:90vh; margin:0px" class="align-center justify-center" data-aos="fade-up"
          data-aos-duration="1500">
          <v-col class="offMb" cols="12" md="8">
            <v-card-title class="text-h5" style="
            font-family: Poppins !important;
            font-weight: 500;
            font-size: 32px !important;
            color: #fff;
            line-height: 1;
            text-align: center;
            word-break: inherit !important;
          ">
              Cadastre-se!</v-card-title>
            <div style="background-color: transparent; border-radius: 10px; padding: 0px 25px" class="paddingDialog">
              <v-text-field dark outlined v-model="contato.nome" label="Nome" required>
              </v-text-field>
              <v-text-field dark outlined v-model="contato.email" label="E-mail" required></v-text-field>
              <!-- <v-text-field dark outlined v-model="contato.senha" label="Senha" required></v-text-field> -->
              <v-text-field v-mask="'(##) #####-####'" dark outlined v-model="contato.telefone" :counter="15"
                label="Telefone" required></v-text-field>
              <!-- <v-text-field v-mask="'###.###.###-##'" dark outlined v-model="contato.cpf" :counter="14" label="CPF"
                required>
              </v-text-field> -->
              <v-btn :disabled="loading" @click="enviarFormulario" block class="mr-4" type="submit" style="
              background-color: #70a9b7;
              color: #fff;
              font-family: Poppins;
              font-weight: 300;
              border-radius: 5px;
              text-transform: inherit;
            ">
                Enviar
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn  color="blue" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>

</template>


<script>
export default {
  name: 'Home',

  data: () => ({
    dialog: false,
    loading: false,
    snackbar: false,
    blogHome: [],
    text: "Seu contato foi enviado! Obrigado por sua mensagem, responderemos em breve.",
    contato: { nome: "", email: "", senha: "", telefone: "", cpf: "", },
    timeout: 2000,
  }),

  created() {
    fetch("https://blogs.abare.cloud/items/solanute?filter[status][_eq]=published&sort=-date_created")
      .then((response) => response.json())
      .then((data) => {
        this.blogHome = data.data;
      });
  },

  mounted() {
    // window.scrollTo(0, 0);
    window.scrollTo(0, 0);

  },

  methods: {
    enviarFormulario() {
      if ((this.contato.nome === "")) { 
                    alert("Por favor preencha todos os campos")
                    return;
                }
                else if ((this.contato.email === "")){
                    alert("Por favor preencha todos os campos")
                    return;
                }
                else if ((this.contato.telefone === "")){
                    alert("Por favor preencha todos os campos")
                    return;
                }
                // else if ((this.contato.cpf === "")){
                //     alert("Por favor preencha todos os campos")
                //     return;
                // }
            else{
            this.loading = true;
      fetch("https://gestao.abare.cloud/items/leads", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ id_formulario: 76, lead: this.contato }),
      })
        .then((response) => response.text())
        .then((data) => {
          window.console.log(data);
          // alert("contato enviado com sucesso");
          this.dialog = false;
          this.snackbar = true;
          this.enviarEmail()
        });
      }
    },

    enviarEmail() {
            fetch("https://gestao.abare.cloud/email/gen-send", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify({
                    novo: true,
                    template: "d-6f0c80f846af4963a599785c85ccef8e",
                    email: 'recepcao@solanute.com.br',
                    dynamic: {
                        nome: this.contato.nome,
                        email: this.contato.email,
                        telefone: this.contato.telefone,
                        // cpf: this.contato.cpf,
                    }
                }),
            })
                .then((response) => response.text())
                .then(() => {
                    //this.$router.push("obrigado");
                    this.r = {};
                });
        },
  },

  computed: {
    intBreakPoint() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 0;
        case "sm":
          return 1;
        case "md":
          return 2;
        case "lg":
          return 3;
        case "xl":
          return 4;
        default:
          return 0;
      }
    },
  },



}
</script>

<style scoped>
@media (min-device-width: 340px) and (max-device-width: 820px) {
  .backgroundTopo {
    background-image: url("../assets/fundoMb.webp") !important;
    height: 100vh !important;
    background-attachment: inherit !important;
    background-size: cover !important;
  }
   .rowBtn{
    margin: 0px !important
  }

  .ultimaCol{
    padding-bottom: 20px !important;
    padding-top: 20px !important;
  }
   .positionMobile {
    position: relative !important;
    right: 0 !important;
    top: initial !important;
    padding: 50px 30px !important;
    width: 360px !important;
  }


  .textoBotao{
    font-size:18px !important;
    width: 235px !important;
  }
  .paddingCol{
    padding-left: 0px !important;
  }
 

  .columMB{
    flex-direction: column !important;
  }

  .textoTitulo {
    font-size: 35px !important;
    padding-top: 400px !important;
  }

  .padBorder {
    border-radius: 20px !important;
    padding-top: 10px !important;
  }

  .bgRetangulo {
    height: 100% !important;
  }

  .widthCard {
    max-width: auto !important;
  }

  .wdEsp {
    width: 85vw !important;
  }

  .especialidade {
    height: auto !important;
  }

  .padCol {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .wdMb {
    width: 351px !important;
  }

  .cardMb {
    margin-top: 25px !important;
  }

  .wdCard {
    max-width: 335px !important;
  }

  .colMb {
    max-width: 100% !important;
  }

  .wdImage {
    width: 90px !important;
  }

  .textoCentral {
    text-align: left !important;
  }

  .padMobile {
    align-items: center;
    justify-content: center;
    display: flex;

  }

  .padTexto {
    padding-left: 10px !important;
  }
}

.backgroundTopo {
  background-image: url("../assets/fundo.webp");
  background-repeat: no-repeat !important;
  background-size: cover;
  height: 85vh;
  background-attachment: absolute;
  background-color: rgba(255, 255, 255, 0.596);
  background-position: center;
}

.backgroundDialog {
  background-image: url("../assets/clinica.webp");
  background-repeat: no-repeat !important;
  background-size: cover;
  height: 500px;
  background-attachment: absolute;
  background-color: rgba(255, 255, 255, 0.596);
  background-position: center;
}

.bgRetangulo {
  background-image: url("../assets/retangulo2.webp");
  background-repeat: no-repeat !important;
  background-size: cover;
  height: auto;
  background-attachment: fixed;
  background-color: rgba(255, 255, 255, 0.596);
  background-position: center-top;
}
</style>
