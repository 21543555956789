<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" md="12" class="align-center justify-center d-flex">
        <v-carousel height="400" hide-delimiter-background show-arrows-on-hover>
            <v-carousel-item v-show = "show2" v-for="(slide, i) in slides" :key="i">
            <v-sheet :color="colors[i]" height="100%">
                <v-row class="fill-height" align="center" justify="center">
                <div class="text-h2">{{ slide }} Slide</div>
                <v-btn @click="show = true">
                    Saiba mais
                </v-btn>
                </v-row>
            </v-sheet>
            </v-carousel-item>

            <v-carousel-item v-for="(slide, i) in slides" :key="i">
                <p>{{slide.nome}}</p>
            </v-carousel-item>
        </v-carousel>
</v-col>
</v-row>

<!-- <v-row>
    <v-col cols="12" md="12">
        <v-carousel v-show="show">
    <v-carousel-item
      v-for="(item,i) in items"
      :key="i"
      :src="item.src"
      reverse-transition="fade-transition"
      transition="fade-transition"
    ></v-carousel-item>
  </v-carousel>
    </v-col>
</v-row> -->
    </v-container>
</template>

<script>
export default {
    data: () => ({
        show: false,
        show2: true,
        especiSolanuti:{},
        

        colors: [
          'indigo',
          'warning',
          'pink darken-2',
          'red lighten-1',
          'deep-purple accent-4',
        ],
        slides: [
            
          'First',
          'Second',
          'Third',
          'Fourth',
          'Fifth',
        ],
    }),
    mounted() {
        // window.scrollTo(0, 0);
        window.scrollTo(0, 0);

    },
  methods: {
        toTop() {
            this.$vuetify.goTo(0);
        },

        carrossada() {
            this.show = true;
            this.show2 = false;
        }
    },
    created() {
        let id = this.$route.params.id;
        fetch("https://gestao.abare.cloud/items/especisolanute/" + id + '/?fields=*,medicos.directus_files_id.id, medicos.directus_files_id.title')
            .then((response) => response.json())
            .then((data) => {
                this.especiSolanuti = data.data;
            });
    },
}
</script>