<template>
    <v-container fluid>
            <v-row class="padMobile" style="padding: 0 160px; " >
                <v-col cols="12" md="12" class="align-center justify-center d-flex flex-column">
                    <v-img class="widthImageTopo" contain style="width:1100px;
                        max-width: 1000px; max-height:400px !important"
                        :src="`https://gestao.abare.cloud/assets/${especiSolanute.capa_especialidade}`">
                    </v-img>
                </v-col>
            </v-row>

            <v-row class="padMobile padTopMob" style="">
                <v-col cols="12" md="2"></v-col>
                <v-col cols="12" md="8">
                    <h1 class="titleDescri " style="color: #444444 !important; opacity: 100% !important">
                        {{ especiSolanute.titulo }}
                    </h1>
                    <p style="color:#222222" v-html="especiSolanute.descricao"></p>
                </v-col>
                <v-col cols="12" md="2"></v-col>
            </v-row>

            <v-row>
                    <v-col cols="12" md="12" class="align-center justify-center d-flex flex-column">
                        <h1 class="titleDescri " style="color: #444444 !important; opacity: 100% !important; font-size:28px">
                            Estes são os nossos profissionais que atuam nessa especialidade:
                        </h1>
                            <v-row>
                                <v-col cols="12" md="12" class="align-center justify-center dflexMb" v-for="medico in especiSolanute.medicos" :key="medico.directus_files_id.id"
                                style="display:flex; padding:0px 80px">
                                <v-img contain style="width:250px !important; max-width: 250px !important; height:350px !important"
                                    :src="`https://gestao.abare.cloud/assets/${medico.directus_files_id.id}`">
                                </v-img>
                                <div class="flex-column wdDiv" style="">
                                <p style="color:#000; font-size:20px; font-weight:700; ">{{medico.directus_files_id.title}}</p>
                                <p style="color:#000; font-size:16px; font-weight:400; " v-html="medico.directus_files_id.description"></p>
                                </div>
                            </v-col>
                            </v-row>
                    </v-col>
            </v-row>
    </v-container>
</template>
<script>
export default {
    data: () => ({
        especiSolanute:{},
    }),
    mounted() {
        // window.scrollTo(0, 0);
        window.scrollTo(0, 0);

    },
  methods: {
        toTop() {
            this.$vuetify.goTo(0);
        },
    },
    created() {
        let id = this.$route.params.id;
        fetch("https://gestao.abare.cloud/items/especisolanute/" + id + '/?fields=*,medicos.directus_files_id.id, medicos.directus_files_id.title, medicos.directus_files_id.description')
            .then((response) => response.json())
            .then((data) => {
                this.especiSolanute = data.data;
            });
    },
}
</script>

<style scoped>
@media (min-device-width: 300px) and (max-device-width: 731px) {

    .padTopMob {
        padding-top: 10px !important;
    }

    .dflexMb{
        display: block !important;
    }

    .wdDiv{
        max-width:250px !important
    }

    .v-image {
        height: 30% !important;
    }

    .titleDescri {
        color: rgb(255, 255, 255);
        line-height: 1.1 !important;
        font-size: 39px !important;
        font-family: Roboto;
        text-align: left !important;
        padding-bottom: 22px;
        font-weight: 800;
        margin-top: 30px !important;
    }

    .widthImageTopo{
        max-width: 359px !important;
        width: 359px !important
    }
}

.titleDescri {
    color: #fff;
    line-height: 1;
    font-size: 48px;
    font-family: Roboto;
    text-align: center;
    opacity: 90%;
    padding-bottom: 22px;
    font-weight: 800
}


</style>